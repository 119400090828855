import React from 'react';
import icons from '../images/icons.svg';

const SvgIcon = ({ icon, className }) => (
  <svg className={className}>
    <use xlinkHref={`${icons}#${icon}`}></use>
  </svg>
);

export default SvgIcon;
