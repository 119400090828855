import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import classNames from 'classnames';
import logo from '../images/logo.png';
import SvgIcon from './svg-icon';

const joinPaths = (...paths) => {
  const joined = paths
    .map((path) => (path.startsWith('/') ? path.substring(1) : path))
    .map((path) => (path.endsWith('/') ? path.substring(0, path.length - 1) : path))
    .join('/');
  return `/${joined}/`;
};

const Header = ({ status, data }) => {
  const {
    navigation: { header }
  } = data;
  const [open, setOpen] = useState(false);
  const toggleMenu = () => setOpen((value) => !value);

  const [itemMap, setItemMap] = useState({});
  const toggleItem = useCallback((i) => setItemMap((value) => ({ ...value, [i]: !value[i] })), []);
  useEffect(() => {
    if (status === 'hidden' || status === 'hiding') {
      setOpen(false);
    }
  }, [status]);

  return (
    <header className={`header header--${status}`}>
      <div className="header__links">
        <div className="container-xl">
          <div className="row">
            <nav
              className="col d-flex justify-content-between align-items-center align-items-md-end w-100"
              aria-label="menu principale"
            >
              <Link to="/" className="logo my-2 d-inline-block">
                <img src={logo} alt="logo" className="m-0" />
              </Link>
              <button
                className={classNames('hamburger d-md-none', {
                  open: open
                })}
                onClick={toggleMenu}
                aria-expanded={open}
                aria-label="menu"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <ul
                className={classNames('navigation ms-md-3 ms-lg-5', {
                  open: open
                })}
              >
                {header.map((item, i) => (
                  <li className="navigation__item" key={item.link}>
                    <div className="d-inline-flex align-items-center">
                      <Link
                        to={item.link}
                        title={item.name}
                        className="navigation__link"
                        activeClassName="active"
                      >
                        {item.name}
                      </Link>
                      {item.children && (
                        <button
                          className={classNames('navigation__arrow', {
                            'navigation__arrow--open': itemMap[i]
                          })}
                          onClick={() => toggleItem(i)}
                          aria-label="sottomenu"
                          aria-expanded={!!itemMap[i]}
                        ></button>
                      )}
                    </div>
                    {item.children && (
                      <ul
                        className={classNames('navigation__children', {
                          'navigation__children--open': itemMap[i]
                        })}
                      >
                        {item.children.map((child) => (
                          <li className="navigation__item" key={child.link}>
                            <Link
                              to={joinPaths(item.link, child.link)}
                              className="navigation__link"
                            >
                              {child.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="header__chips">
        <div className="container-xl">
          <div className="row">
            <div className="col py-2 ps-1 d-flex flex-column d-md-block">
              <a className="chip" href="mailto:francescoloffredo.doc@gmail.com">
                <span className="chip__icon">
                  <SvgIcon icon="envelope-fill" />
                </span>
                <span className="chip__text">francescoloffredo.doc@gmail.com</span>
              </a>
              <a className="chip" href="tel:3755876636">
                <span className="chip__icon">
                  <SvgIcon icon="telephone-fill" />
                </span>
                <span className="chip__text">375-587-6636</span>
              </a>
              <a
                href="https://www.google.com/maps/place/VivaMente/@45.178797,9.157304,13z/data=!4m5!3m4!1s0x0:0x2e547b1086117432!8m2!3d45.1789964!4d9.1574476?hl=it"
                className="chip"
                target="_blank"
                rel="noopener noreferrer"
                title="Via Milazzo 85, 27100 Pavia PV"
              >
                <span className="chip__icon">
                  <SvgIcon icon="geo-alt-fill" />
                </span>
                <span className="chip__text">Studio a Pavia</span>
              </a>
              <a
                href="https://www.google.com/maps/place/360%C2%B0+Psicoterapia+Pedagogia+Formazione/@44.9039012,8.6118487,17z/data=!4m5!3m4!1s0x4787742163ab818b:0x2a208e84b103d8bb!8m2!3d44.9038974!4d8.6140374?hl=it"
                className="chip"
                target="_blank"
                rel="noopener noreferrer"
                title="Via Medaglie D'Oro, 30, 15121 Alessandria AL"
              >
                <span className="chip__icon">
                  <SvgIcon icon="geo-alt-fill" />
                </span>
                <span className="chip__text">Studio ad Alessandria</span>
              </a>
              {/* <a
                href="https://www.google.com/maps/place/Dott.+Francesco+Loffredo,+Psicologo+clinico/@44.997307,9.01718,13z/data=!4m5!3m4!1s0x0:0x7e70fcd5840b53a3!8m2!3d44.9973068!4d9.0171804?hl=it"
                className="chip"
                target="_blank"
                rel="noopener noreferrer"
                title="Via Lamarmora, 9, 27058 Voghera PV"
              >
                <span className="chip__icon">
                  <SvgIcon icon="geo-alt-fill" />
                </span>
                <span className="chip__text">Studio a Voghera</span>
              </a> */}
              <a
                className="chip"
                href="https://www.linkedin.com/in/loffredof-dot"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="chip__icon">
                  <SvgIcon icon="linkedin" />
                </span>
                <span className="chip__text">Francesco Loffredo</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
export const fragment = graphql`
  fragment HeaderFragment on Query {
    navigation: navigationJson {
      header {
        link
        name
        children {
          link
          name
        }
      }
    }
  }
`;
