import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';
import Header from './header';
import Footer from './footer';

const OFFSET_TOP = 300;
const OFFSET_SCROLL = 420;

const Layout = ({ className, data, children }) => {
  const [headerStatus, setHeaderStatus] = useState('default');

  useEffect(() => {
    const listener = throttle(() => {
      if (document.body.scrollTop > OFFSET_TOP || document.documentElement.scrollTop > OFFSET_TOP) {
        if (
          document.body.scrollTop > OFFSET_SCROLL ||
          document.documentElement.scrollTop > OFFSET_SCROLL
        ) {
          setHeaderStatus((current) => (current === 'hidden' ? 'hiding' : 'scrolled'));
        } else {
          setHeaderStatus((current) => (current === 'default' ? 'hidden' : 'hiding'));
        }
      } else {
        setHeaderStatus('default');
      }
    }, 100);
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  return (
    <div className={className}>
      <Header status={headerStatus} data={data} />
      <main className={`${headerStatus !== 'default' ? 'scrolled' : ''}`}>{children}</main>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
