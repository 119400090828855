import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const Seo = ({ description, meta, title, ogImage, ogType = 'website', data: { site } }) => {
  const metaDescription = description || site.siteMetadata.description;

  const {
    share,
    site: {
      siteMetadata: { siteUrl }
    }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
      share: file(relativePath: { eq: "social-share-squared.png" }) {
        childImageSharp {
          resize(width: 630) {
            src
            width
            height
          }
        }
      }
    }
  `);

  const shareImage = ogImage || share.childImageSharp.resize;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'it'
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: title
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:type`,
          content: ogType
        }
      ]
        .concat(
          shareImage
            ? [
                {
                  property: `og:image`,
                  content: siteUrl + shareImage.src
                },
                {
                  property: `og:image:width`,
                  content: shareImage.width
                },
                {
                  property: `og:image:height`,
                  content: shareImage.height
                }
              ]
            : []
        )
        .concat(meta)}
    />
  );
};

Seo.defaultProps = {
  lang: `it`,
  meta: [],
  description: ``
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired
};

export default Seo;
export const fragment = graphql`
  fragment SeoFragment on Query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;
