import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <ul className="footer__list d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start">
              <li>
                <span>© {new Date().getFullYear()} - Francesco Loffredo</span>
              </li>
              <li>
                <a
                  href="https://www.iubenda.com/privacy-policy/77869806"
                  className="footer__link d-inline-block ml-2 iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe"
                  title="Privacy Policy "
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href="/admin"
                  className="footer__admin d-none d-lg-inline-block"
                  aria-hidden={true}
                  rel="nofollow"
                  tabIndex={-1}
                >
                  Sezione amministratori
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
